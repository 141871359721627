.nav {
  height: 50px;
  border: 1px solid #dfdfdf;
}
.header {
  background: #9d2042;
  margin: 5px;
  border: 2px solid #dfdfdf;
  border-radius: 20px;
  padding: 5px;
}
.div-header {
  height: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.support {
  margin-right: 20px;
}
.languages {
  height: 80%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.icon {
  flex-wrap: wrap;
}
